import Phaser from 'phaser'
import PlayerCharacter from '~/actors/PlayerCharacter'

export default class DemoScene extends Phaser.Scene
{
    public player: PlayerCharacter;
    public cursors!: Phaser.Types.Input.Keyboard.CursorKeys;

	constructor()
	{
		super('demo')
        this.player = new PlayerCharacter(this);
	}

    init()
    {
        this.cursors = this.input.keyboard.createCursorKeys();
    }

	preload()
    {
        this.player.preload();
        this.load.audio('battle_music', 'music/BRPG_Take_Courage_Lite_Loop.wav');
    }

    create()
    {
        this.cameras.main.setBackgroundColor('rgba(255, 0, 0, 0.4)');
        this.cameras.main.fadeIn(1000, 0, 0, 0);
        this.player.spawn(200, 400);

        const backgroundMusic = this.sound.add('battle_music');
        backgroundMusic.play({loop: true, volume: 0.5});
    }

    update(): void {
        this.player.handleMovement(this.cursors);
    }
}
