export default class PlayerCharacter
{
    public scene: Phaser.Scene;
    public key: string;
    public sprite: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody | undefined;

    public velocity: integer = 250;

    constructor(scene: Phaser.Scene, key = 'PlayerCharacter')
    {
        this.scene = scene;
        this.key = key;
    }

    preload(): void
    {
        this.scene.load.image(this.key, 'images/static/player.png');
    }

    spawn(startingX: integer = 0, startingY: integer = 0): Phaser.Types.Physics.Arcade.SpriteWithDynamicBody
    {
        this.sprite = this.scene.physics.add.sprite(startingX, startingY, this.key);
        this.sprite.setScale(0.2, 0.2);
		this.sprite.setCollideWorldBounds(true);

        return this.sprite;
    }

    handleMovement(cursors: Phaser.Types.Input.Keyboard.CursorKeys): void
    {
        if (!this.sprite) {
            return;
        }

        let xVelocity = 0;
        let yVelocity = 0;

        if (cursors.left.isDown) {
            xVelocity -= this.velocity;
        }

        if (cursors.right.isDown) {
            xVelocity += this.velocity;
        }

        if (cursors.up.isDown) {
            yVelocity -= this.velocity;
        }

        if (cursors.down.isDown) {
            yVelocity += this.velocity;
        }

        this.sprite.setVelocity(xVelocity, yVelocity);
    }
}