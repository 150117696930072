import Phaser from 'phaser'
import DemoScene from './DemoScene';

export default class MainMenuScene extends Phaser.Scene
{
    private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
    private buttons: Phaser.GameObjects.Image[] = [];
    private buttonSelector!: Phaser.GameObjects.Image;
	private selectedButtonIndex = 0;

	constructor()
	{
		super('main-menu')
	}

    init()
    {
        this.cursors = this.input.keyboard.createCursorKeys();
    }

	preload(): void
    {
        this.load.image('cursor', 'images/static/cursor1.png');
        this.load.image('button', 'images/static/button_08.png');
        this.load.image('button_frame', 'images/static/button_08_frame.png');
        this.load.audio('button_locked', 'sfx/Button7.mp3');
        this.load.audio('button_confirmed', 'sfx/Select5.mp3');
    }

    create(): void
    {
        this.input.setDefaultCursor('url(images/static/cursor1.png), pointer');

        const menuX = this.scale.width * 0.5;
        const menuY = this.scale.height * 0.55;

        this.add.text(menuX, menuY/2, 'Unnamed Stealth Game').setFontSize(48).setOrigin(0.5);

        const playButton = this.add.image(menuX, menuY, 'button')
            .setScale(0.33)
            .setInteractive()
            .addListener('selected', this.doStart.bind(this))
            .addListener('pointerdown', this.doStart.bind(this))
            .addListener('pointerover', this.selectButton.bind(this, 0));
        this.add.text(playButton.x, playButton.y, 'Play').setOrigin(0.5);
        this.buttons.push(playButton);

        const settingsButton = this.add.image(menuX, playButton.y + playButton.displayHeight + 15, 'button')
            .setScale(0.33)
            .setInteractive()
            .addListener('selected', this.doNotImplemented.bind(this))
            .addListener('pointerdown', this.doNotImplemented.bind(this))
            .addListener('pointerover', this.selectButton.bind(this, 1));
        this.add.text(settingsButton.x, settingsButton.y, 'Settings').setOrigin(0.5);
        this.buttons.push(settingsButton);

        const creditsButton = this.add.image(menuX, settingsButton.y + settingsButton.displayHeight + 15, 'button')
            .setScale(0.33)
            .setInteractive()
            .addListener('selected', this.doNotImplemented.bind(this))
            .addListener('pointerdown', this.doNotImplemented.bind(this))
            .addListener('pointerover', this.selectButton.bind(this, 2));
        this.add.text(creditsButton.x, creditsButton.y, 'Credits').setOrigin(0.5);
        this.buttons.push(creditsButton);

        this.buttonSelector = this.add.image(playButton.x, playButton.y, 'button_frame').setScale(0.33);
        this.selectButton(0);

        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            playButton.removeAllListeners();
            settingsButton.removeAllListeners();
            creditsButton.removeAllListeners();
        })
    }

    update(): void
    {
        if (Phaser.Input.Keyboard.JustDown(this.cursors.up!)) {
            this.moveCursor(-1);
        } else if (Phaser.Input.Keyboard.JustDown(this.cursors.down!)) {
            this.moveCursor(1);
        } else if (Phaser.Input.Keyboard.JustDown(this.cursors.space!)) {
            this.confirmSelection();
        }
    }

    selectButton(index: integer)
    {
        const currentButton = this.buttons[this.selectedButtonIndex];
        currentButton.setTint(0xffffff);

        const newButton = this.buttons[index];
        newButton.setTint(0x66ff7f);

        this.buttonSelector.copyPosition(newButton);
        this.selectedButtonIndex = index;
    }

    moveCursor(direction: integer): void
    {
        this.selectButton((this.selectedButtonIndex + 3 + Math.sign(direction)) % 3);
    }

    confirmSelection(): void
    {
        const button = this.buttons[this.selectedButtonIndex];
        button.emit('selected');
    }

    doStart()
    {
        const buttonConfirmedSound = this.sound.add('button_confirmed');
        buttonConfirmedSound.play();
        this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, (cam, effect) => {
            this.scene.start('demo');
        }).fadeOut(1000, 0, 0, 0);
    }

    doNotImplemented()
    {
        const buttonLockedSound = this.sound.add('button_locked');
        buttonLockedSound.play();
        this.cameras.main.shake(75);
    }
}
