import Phaser from 'phaser'
import DemoScene from './scenes/DemoScene'
import MainMenuScene from './scenes/MainMenuScene'

const config: Phaser.Types.Core.GameConfig = {
	type: Phaser.AUTO,
	width: 800,
	height: 600,
	physics: {
		default: 'arcade'
	},
	scene: [MainMenuScene, DemoScene]
};

export default new Phaser.Game(config);
